import { SubscribeNewsControllerService } from '@/__generated__/FrontApi';
import InputText from '@/components/Input/InputText';
import Section from '@/components/Section';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithoutTitle from '@/layouts/LayoutWithoutTitle';
import { usePopupStore } from '@/stores/PopupStore';
import { useLocation } from '@reach/router';
import React, { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const NewsLetterContainer = styled.div`
  max-width: 700px;
  background: #e5e6e8;

  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .inner-content {
    /* display: flex;
    flex-wrap: wrap;
    position: relative; */

    .sec-header {
      width: 33.4%;
      padding-bottom: 0;
    }
    .newsletter-input-contents {
      width: 66.6%;
      padding-left: 10px;
    }

    ${breakpoint(`tablet`)} {
      .sec-header,
      .newsletter-input-contents {
        width: 100%;
        padding: 0;
      }

      .sec-header {
        padding-bottom: 40px;
      }
    }
  }

  .form-flex {
    .form-flex-cell {
      & + .form-flex-cell {
        padding-left: 32px;
      }
    }
    .newsletter-cell-name {
      width: 160px;
    }
    .newsletter-cell-email {
      width: 256px;
    }
    /* ${breakpoint(800)} {
      display: block;
      .form-flex-cell {
        display: block;
        margin-top: 8px;
        button {
          width: 100%;
        }
        & + .form-flex-cell {
          padding: 0;
        }
        &.newsletter-cell-name {
          width: 40%;
          display: inline-block;
        }
        &.newsletter-cell-email {
          width: 60%;
          display: inline-block;
          padding-left: 2%;
        }
      }
    } */
  }

  .button-submit-skyblue {
    /* display: inline-block; */
    border: none;
    width: 224px;
    height: 56px;
    background: #1cabe2;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -1.2px;
    ${breakpoint(`mobile`)} {
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: -0.8px;
      margin-top: 8px;
      width: 100%;
    }
  }
  .private-text {
    font-size: 14px;
    color: #828385;
    margin-top: 10px;
    line-height: 2;
    letter-spacing: -0.8px;
    ${breakpoint(`mobile`)} {
      font-size: 12px;
    }
  }
`;

const Header = styled.header`
  padding-bottom: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .more {
    font-weight: 700;

    ${breakpoint('mobile')} {
      padding-right: 24px;
      height: 24px;
      font-size: 2rem;

      .txt {
        overflow: hidden;
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: 1px;
        height: 1px;
        font-size: 1px;
        line-height: 1;
      }
    }
  }
`;

const SectionForm = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 57px;
  padding-bottom: 84px;

  ${breakpoint('tablet')} {
    padding-top: 37px;
    padding-bottom: 74px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export interface HomeSubscribeFormData {
  name: string;
  email: string;
  entryPath: string;
  entryType: string;
}

function index() {
  const { register, handleSubmit, reset } = useForm<HomeSubscribeFormData>();
  const popupStore = usePopupStore();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const trackcode = queryParams.get(`trackcode`);

  const onSubmit: SubmitHandler<HomeSubscribeFormData> = async ({
    name,
    email,
  }) => {
    try {
      if (
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email.trim())
      ) {
        alert('이메일 주소가 올바르지 않습니다.');
        return;
      }
      const {
        resultCode,
      } = await SubscribeNewsControllerService.postSubscribeNewsUserUsingPost({
        email,
        name,
        entryType: 'enews',
        entryPath: trackcode ?? undefined,
      });

      if (resultCode === 'success') {
        popupStore.show(
          '유니세프 소식 받아보기 신청이 완료되었습니다.\n유니세프와 함께 전 세계 어린이들에 대한 다양한\n이야기를 들어보세요.',
        );
        // 폼 초기화
        reset();
      }
    } catch (e) {
      if (e.response) {
        popupStore.show(e.response.data.data.returnMessage);
      }
    }
  };

  return (
    <LayoutWithoutTitle hideGnb>
      <SectionForm>
        <NewsLetterContainer>
          <Header className="sec-header">
            <Tit size="s2">
              유니세프와 함께하는 소식을 <br />
              놓치지 마세요
            </Tit>
          </Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
              <InputText
                style={{ maxWidth: '300px' }}
                name="name"
                ref={register({ required: true })}
                placeholder="이름 입력"
              />
              <InputText
                name="email"
                style={{ maxWidth: '300px' }}
                ref={register({ required: true })}
                placeholder="이메일 입력"
              />
              <p className="private-text">
                소식 전달을 목적으로 이름, 이메일주소를 수집하며, 수신거부 시
                까지 이용 ∙보유합니다.
              </p>
              {/* pc 오른쪽 */}
              <button type="submit" className="button-submit-skyblue">
                소식 받아보기
              </button>
            </FormContainer>
          </form>
        </NewsLetterContainer>
      </SectionForm>
    </LayoutWithoutTitle>
  );
}

export default index;
